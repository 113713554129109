.tabs {
  .nav-tabs {
    .nav-item {
      cursor: pointer;
    }
  }

  .tab-content {
    @extend .mt-3;
  }
}

.accordion {
  .nav-link {
    cursor: pointer; 
  }
}