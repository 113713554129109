.news-item-row {
    @include media-breakpoint-down(md) {
        > div:first-child {
            order: 2;
        }
        > div:last-child {
            margin-top: 1rem;
            margin-bottom: 1rem;
            order: 1;
        }
    }
    .img-box {
        .responsive {
            width: 100%;
        }
    }
}

.news-details {
    @extend .my-3;

    &:first-letter {
        text-transform: uppercase;
    }

    span {
        font-style: italic;

        &.news-date {
            margin-right: .35rem;
        }
    }
}